<template>
    <div class="list" id="courseList">
        <div
            v-show="activeTab === item.showTab"
            class="sub-list"
            v-for="item in menuList"
            :key="`${item.id}_${item.type}`"
        >
            <div @click="onTabMenu(item)" class="sub-title" :class="item.hasPlayVideo ? 'has_course' : ''">
                <span>{{ item.title }}</span>
                <van-icon v-if="item.unlocked" :class="item.isOpen ? 'rotate-icon' : ''" name="arrow-down" />
                <img v-else class="suo-icon" src="https://res.peki.vip/20230317/a33401368bbb445b8c16747ddcde6425.png" />
            </div>

            <div v-if="menuCourseMap" class="course-list" :class="item.isOpen ? '' : 'hidden'">
                <div @click="onPlayCourse(course, item)" v-for="course in menuCourseMap[item.id]" :key="course.id">
                    <CourseModule :course="course" :currentCourse="currentCourse" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseModule from './CourseModule'
import { coursePosition, getAttendTime } from '@/utils/tools.js'
import { getStageCourse, getPackageCourses, getPackageStage } from '@/api/studyApi.js'

export default {
    props: ['listData', 'activeTab', 'currentCourse', 'endedKey', 'product'],
    data() {
        return {
            menuList: [],
            menuCourseMap: null,
        }
    },
    watch: {
        endedKey: function () {
            this.menuList.forEach((item) => {
                if (item.hasPlayVideo) {
                    this.updateMenuCourseList(item)
                }
            })
            this.getPackageStageApi()
        },
    },
    components: {
        CourseModule,
    },
    created() {
        this.initCourse()
    },
    methods: {
        onTabMenu(item) {
            if (item.unlocked) {
                this.menuList.forEach((menu) => {
                    if (menu.id === item.id) {
                        menu.isOpen = !menu.isOpen
                    } else {
                        menu.isOpen = false
                    }
                })
                this.updateMenuCourseList(item)
                this.$forceUpdate()
            } else {
                this.$toast('学完上一个阶段，才能解锁哦~')
            }
        },
        onPlayCourse(course, item) {
            if (course.unlocked) {
                this.menuList.forEach((menu) => {
                    if (menu.id === item.id && menu.type === item.type) {
                        menu.hasPlayVideo = true
                    } else {
                        menu.hasPlayVideo = false
                    }
                })
                this.updateMenuCourseList(item)
                coursePosition(this.product.packageNo, [item.id, course.id, 0])
                this.$emit('courseChange', { ...course })
            } else {
                this.$toast('学完上一个课程，才能解锁哦~')
            }
        },
        updateMenuCourseList(item) {
            this.getMenuCourse(item).then((courseList) => {
                this.menuCourseMap[item.id] = courseList
                this.$forceUpdate()
            })
        },
        async initCourse() {
            // 历史位置
            const cPosition = coursePosition(this.product.packageNo)

            // 设置默认选中menu
            let lastIndex = 0
            const menuCourseMap = {}
            this.listData.forEach((item, index) => {
                this.menuList.push({ ...item, isOpen: false, hasPlayVideo: false })
                menuCourseMap[item.id] = []
                if (cPosition[0] === item.id) lastIndex = index
            })
            this.$emit('initTab', this.menuList[lastIndex]['showTab'])
            this.menuList[lastIndex]['isOpen'] = true
            this.menuList[lastIndex]['hasPlayVideo'] = true

            // 获取选中menu下，课程列表
            const courseList = await this.getMenuCourse(this.menuList[lastIndex])
            const currentMenu = this.menuList[lastIndex]
            menuCourseMap[currentMenu.id] = courseList
            this.menuCourseMap = menuCourseMap

            // 设置默认播放课程
            let activeCourse = { ...courseList[0] }
            courseList.forEach((course) => {
                if (course.id === cPosition[1]) {
                    activeCourse = { ...course }
                }
            })
            this.$emit('courseChange', activeCourse)

            this.$nextTick(() => {
                this.initListBoxScroll()
            })
        },
        async getMenuCourse(menu) {
            let res = null
            if (menu.type === 2) {
                res = await getStageCourse(menu.packageId, menu.stageId)
            } else if (menu.type === 3 || menu.type === 'live') {
                res = await getPackageCourses({
                    packageNo: menu.packageNo,
                    mainPackageNo: this.product.packageNo,
                })
            }
            const list = res ? res.data : []
            list.forEach((item) => {
                item.menuId = menu.id
                item.packageId = menu.packageId
                item.packageNo = menu.packageNo
                if (menu.stageId) {
                    item.stageId = menu.stageId
                }
                if (item.unlocked === undefined || item.unlocked === null) {
                    item.unlocked = true
                }
                if (item.learnRecord) {
                    const { learnRecord, courseDuration } = item
                    item.attendScale = parseInt((learnRecord.attendDuration / (courseDuration / 2)) * 100)
                    item.attendScale = item.attendScale >= 100 ? 100 : item.attendScale
                    item.attendText = getAttendTime(learnRecord.attendDuration)
                }
            })
            return list
        },

        // 最后一节课程结束/更新菜单解锁状态
        getPackageStageApi() {
            let isStageLastCourse = false
            this.menuList.forEach((item) => {
                const list = this.menuCourseMap[item.id]
                if (list.length !== 0 && list[list.length - 1]['id'] === this.currentCourse.id) {
                    isStageLastCourse = true
                }
            })
            if (isStageLastCourse) {
                getPackageStage(this.currentCourse.packageId).then((res) => {
                    res.data.forEach((stage) => {
                        this.menuList.forEach((menu) => {
                            if (menu.stageId === stage.id) {
                                menu.unlocked = stage.unlocked
                            }
                        })
                    })
                    this.$forceUpdate()
                })
            }
        },
        initListBoxScroll() {
            setTimeout(() => {
                let topNum = 0
                let i = -1
                this.menuList.forEach((item) => {
                    if (this.activeTab === item.showTab) i += 1
                    if (item.hasPlayVideo) {
                        topNum += i * 45
                        this.menuCourseMap[item.id].forEach((course, index) => {
                            if (course.id === this.currentCourse.id) {
                                topNum += index * 45
                            }
                        })
                    }
                })
                document.getElementById('courseList').scrollTop = topNum
            }, 100)
        },
    },
}
</script>
<style scoped lang="scss">
.list {
    overflow-y: scroll;
    position: absolute;
    top: 92px;
    left: 24px;
    right: 24px;
    bottom: 28px;
    .sub-list {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        .sub-title {
            width: 100%;
            height: 44px;
            padding: 0 8px;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.9);
            font-size: 14px;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            -moz-user-select: none; /*火狐*/
            -webkit-user-select: none; /*webkit浏览器*/
            -ms-user-select: none; /*IE10*/
            -khtml-user-select: none; /*早期浏览器*/
            user-select: none;
            cursor: pointer;
            position: sticky;
            top: -1px;
            z-index: 9;
            &.has_course {
                color: #f0941d;
            }
            span {
                display: block;
                max-width: 240px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .van-icon {
                transition: all 0.3s;
                font-size: 14px;
            }
            .rotate-icon {
                transform: rotate(180deg);
            }
            .suo-icon {
                width: 18px;
                background: none;
            }
        }
    }
}
.hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
    border: none;
}
</style>
