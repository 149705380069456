<template>
    <van-notice-bar
        id="tipsNotice"
        :style="nStyle"
        :speed="40"
        text="本课程版权归本公司所有，仅限个人学习，严禁任何形式的录制、传播和账号分享，一经发现，平台将依法保留追究权，情节严重者将承担法律责任。"
    />
</template>
<script>
export default {
    props: ['nStyle'],
}
</script>
<style lang="scss" scoped>
#tipsNotice {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 10px;
    border-radius: 24px;
    height: 24px;
    z-index: 9;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.6) !important;
    pointer-events: none;
}
</style>
